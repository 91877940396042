/** @jsx jsx */
import { jsx } from "theme-ui"
import Flex from "../../Atomic/Flex"

import { Link } from "gatsby"

export default function NavBarLink({ to, text }) {
  return (
    <Flex sx={{ alignItems: "center" }}>
      <Link
        sx={{
          // color: "greyScale.6",
          color: "white",
          textShadow: "neon",
          textDecoration: "none",
          "&:hover": { textShadow: "neonFocus" },
          marginRight: "sm",
          fontWeight: "light",
        }}
        to={to}
      >
        {text}
      </Link>
    </Flex>
  )
}
