/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useRef } from "react"

import { motion } from "framer-motion"

import NavBarLink from "./NavBarLink"
import Toggle from "./Toggle"

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false)
  const variants = {
    open: { opacity: 1, display: "flex" },
    closed: { opacity: 0, display: "none" },
  }
  const navVariants = {
    open: { width: "100%", borderTopRightRadius: 0 },
    closed: { width: "41px", borderTopRightRadius: 4 },
  }

  const containerRef = useRef(null)

  return (
    <motion.nav
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // backgroundColor: "greyScale.2",
        backgroundColor: "background",
        marginTop: "sm",
        paddingY: "sm",
        fontSize: "md",
        minHeight: 45,
      }}
      variants={navVariants}
      animate={isOpen ? "open" : "closed"}
      transition={{ duration: 0.5 }}
      ref={containerRef}
      initial={false}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Toggle
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      <motion.div
        sx={{ display: "flex" }}
        variants={variants}
        animate={isOpen ? "open" : "closed"}
        transition={{ duration: 0.5 }}
      >
        <NavBarLink to={"/homepage/projects"} text={"Projects"} />
        <NavBarLink to={"/homepage/art"} text={"Art"} />
        <NavBarLink to={"/homepage/blog"} text={"Blog"} />
        <NavBarLink to={"/homepage/games"} text={"Games"} />
      </motion.div>
    </motion.nav>
  )
}
