import * as React from "react"

function SvgLogo(props) {
  return (
    <svg width={48} height={45} fill="none" {...props}>
      <g filter="url(#Logo_svg__filter0_dd)">
        <path
          d="M4.105 29.994c.61-.24 5-6.146 9.728-12.707M42.104 29.84L32.007 17.214m0 0l-6.353-9.497c-1.172-1.753-3.74-1.781-4.956-.059a1251.35 1251.35 0 01-6.865 9.63m18.174-.074l-8.736-.218-9.438.291M4 4.154l16.875 22.498a3 3 0 004.833-.046L42 4M21.135 34.559v0a2.902 2.902 0 014.396-.018v0a2.902 2.902 0 01-.099 3.916v0a2.902 2.902 0 01-4.166.017v0a2.902 2.902 0 01-.131-3.915z"
          stroke="#fff"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="Logo_svg__filter0_dd"
          x={0.5}
          y={0.5}
          width={47.104}
          height={44.347}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={1} />
          <feColorMatrix values="0 0 0 0 0.725 0 0 0 0 0.120833 0 0 0 0 0.664583 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.529375 0 0 0 0 0.04375 0 0 0 0 0.7 0 0 0 0.25 0" />
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgLogo
