/** @jsx jsx */
import { jsx } from "theme-ui"

export function H1({ children }) {
  return (
    <h1
      sx={{
        color: "white",
        textShadow: "neon",
        fontWeight: "thin",
        fontSize: ["lg", "xl", "h2", "h1"],
        letterSpacing: "3px",
        marginBottom: "0",
      }}
    >
      {children}
    </h1>
  )
}
