/** @jsx jsx */
import { jsx } from "theme-ui"

import { H1 } from "../Atomic/Heading/Heading"
import Flex from "../Atomic/Flex"

// import LOGO from "./logo2.png"

import NavBar from "./NavBar"
import { Link } from "gatsby"

import Logo from "../../icons/Logo"

export default function Header({ headerText }) {
  return (
    <Flex
      sx={{
        position: "sticky",
        top: "0px",
        zIndex: "99",
        backgroundColor: "blue",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          margin: "sm",
          // borderTopLeftRadius: "md",
          // borderTopRightRadius: "md",
          borderRadius: "md",
          padding: "sm",
          backgroundColor: "background",
          boxShadow: "-2px 2px 4px rgba(0, 0, 0, 0.25);",
          color: "white",
        }}
      >
        <Link
          to={`/`}
          sx={{ display: "flex", marginRight: ["2xs", "xs", "sm", "md"] }}
        >
          <Logo />
        </Link>
        <H1>{headerText.toUpperCase()}</H1>
      </Flex>
      <NavBar />
    </Flex>
  )
}
